
// Colors //
$brand-primary: #26273F;
$brand-secondary: #40B7B0;
$brand-tertiary : #E6ECEE;

$dataviz-1: #66D8DF;
$dataviz-2: #6FB7FF;
$dataviz-3: #8878F7;
$dataviz-4: #B851C1;
$dataviz-5: #FF7CA3;
$dataviz-6: #FF9FCD;
$dataviz-7: #FFA34F;
$dataviz-8: #FADE7E;

$status-red: #EB535C;
$status-orange: #FFA34F;
$status-green: #6DD795;

$nav-hover: #101129;

$white: #FFFFFF;
$black: #000000;
$lightgrey: #CFCFCF;

$minimum: #F18D30;
$target: #B851C1;
$stretch: #6FB7FF;
$actual: $dataviz-1;

$lowest: #FFCA52;
$medium: $dataviz-3;
$highest: $dataviz-1;
