@import '../../styles/vars';

.dashboard, .financial-priorities {
    width: 100%;

    section {
        a {
            color: $brand-primary;
            text-decoration: none;

            &:hover {
                text-decoration-color: $brand-primary;
                text-decoration-line: underline;
            }
        }
    }

    section.action-plan {
        width: 100%;
        clear: both;
        position: relative;

        h1 {
            margin: 0 0 10px 0;
        }

        h3 {
            font-weight: 14px;
            margin: 10px 0 10px 30px;
        }

        .help {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 5px;
            right: 0px;

            span {
                display: inline-block;
                margin: 0 10px 0 0;
                color: $brand-secondary;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            img {
                width: 40px;
                height: 40px;
                cursor: pointer;
            }
        }

        .fp-summary-empty {
            width: 100%;
            height: 200px;
            border-radius: 10px;
            margin: 30px 0 0 0;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: url('../../images/backgrounds/fpap-empty.jpg');
            background-size: 1080px 200px;
            background-repeat: no-repeat;

            button {
                background-color: $brand-primary;
                color: $white;
                border-radius: 10px;
                border: none;
                padding: 10px 12px 10px 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
            }

            label {
                color: $white;
                margin: 25px 0 0 0;
            }
        }

        .tiers {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            flex-direction: row;
            margin: 40px 0 0 0;

            .tier {
                width: 16.66%;
                height: 200px;
                background-color: $white;
                border-radius: 10px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                cursor: pointer;

                .metadata {
                    width: 100%;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 20px 0;

                    img {
                        height: 25px;
                        margin-right: 10px;

                        &:nth-of-type(1) {
                            width: 30px;
                        }
                    }

                    h2 {
                        margin: 0;
                    }
                }

                .pie {
                    width: 100%;
                    height: 105px;
                    position: relative;
                    cursor: pointer;

                    .recharts-wrapper {
                        margin: 0 auto;
                        cursor: pointer !important;
                    }

                    label {
                        position: absolute;
                        top: 42%;
                        left: 32%;
                        font-weight: 800;
                        font-size: 24px;
                        line-height: 29px;
                        color: $brand-secondary;
                        width: 100%;
                        text-align: center;
                        top: 35%;
                        left: 0;
                        cursor: pointer;
                    }

                    svg.arrow-icon {
                        position: absolute;
                        bottom: -20px;
                        right: 10px;
                    }
                }
            }
        }
    }

    section.your-agenda, section.section-next-steps, .category-detail {
        width: 55%;
        height: 639px;
        background-color: $white;
        padding: 20px 30px 40px 30px;
        margin: 110px 0 105px 0;
        border-radius: 10px;
        float: left;
        position: relative;

        .next-steps, .tasks {
            width: 100%;
            margin: 0 0 50px 0;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            position: relative;

            p {
                margin-left: 30px;
                color: #B3B3B3;
            }

            &.plan-empty-state {
                width: 600px;
                height: 165px;
                border-radius: 10px;
                background: url('../../images/backgrounds/agenda-plan.jpg');
                background-repeat: no-repeat;
                background-size: 600px 165px;
                margin: 15px 0 50px 0;
                justify-content: center;
            }

            &.tasks-empty-state {
                width: 600px;
                height: 235px;
                border-radius: 10px;
                background: url('../../images/backgrounds/agenda-tasks.jpg');
                background-repeat: no-repeat;
                background-size: 600px 235px;
                margin: 15px 0;
                justify-content: center;
            }

            .next-step, .task-line {
                background-color: $brand-primary;
                border-radius: 10px;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
                padding: 10px 12px 10px 15px;
                margin: 10px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                .faux-checkbox, img {
                    width: 20px;
                    height: 20px;
                    border: 1.5px solid rgba(255, 255, 255, 0.6);
                    border-radius: 3px;
                    margin-right: 15px;
                }

                .MuiButtonBase-root {
                    padding: 0 18px 0 0px;
                    position: relative;
                    left: 2px;

                    .MuiSvgIcon-root {
                        width: 30px;
                        height: 30px;
                    }
                }

                .task {
                    color: $white;
                    margin: 0 15px 0 0;
                    flex: 1;
                }

                &.red {
                    background-color: rgba(235, 83, 92, 0.15);

                    .faux-checkbox {
                        border-color: rgba(235, 83, 92, 0.6);
                    }

                    .task {
                        color: $brand-primary;
                    }
                }

                &.orange {
                    background-color: rgba(255, 158, 45, 0.15);

                    .faux-checkbox {
                        border-color: rgba(255, 158, 45, 0.6);
                    }

                    .task {
                        color: $brand-primary;
                    }
                }

                &.green {
                    background-color: rgba(124, 212, 183, 0.3);

                    .MuiCheckbox-root {
                        width: 20px;
                        height: 20px;
                        color: #49D1A0;
                    }

                    .MuiCheckbox-colorSecondary.Mui-checked {
                        color: #49D1A0;
                    }

                    .task {
                        color: $brand-primary;
                    }
                }

                &.no-next-steps {
                    background-color: $white;
                    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);

                    img {
                        border: none;
                    }

                    .task {
                        font-size: 15px;
                        line-height: 19px;
                        color: #000000;
                    }
                }
            }

            .no-recurring-task {
                background-color: $brand-primary;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 20px;
                margin: 10px auto;
                padding: 7px 20px;
                color: $white;
                font-size: 18px;
                line-height: 26px;
                align-self: center;
                cursor: pointer;
            }
        }
    }

    section.financial-fact, section.quote-and-slideshow {
        width: 39%;
        height: 610px;
        margin: 110px 0 105px 0;
        float: left;
        position: relative;

        &.mobile-version {
            display: none;
        }

        .fact, .quote-or-collapsed {
            width: 245px;
            height: 530px;
            position: absolute;
            top: 40px;
            left: 70px;
            background-color: rgba(38, 39, 63, 0.9);
            border-radius: 10px;
            padding: 35px 20px 20px 25px;
            z-index: 10;

            h1, p {
                color: $white;

                a {
                    color: $brand-secondary;
                }
            }

            svg.arrow-icon, svg.plus-icon {
                position: absolute;
                bottom: 20px;
                right: 20px;
                cursor: pointer;
            }
        }

        .background {
            width: 294px;
            height: 586px;
            border-radius: 10px;
            position: absolute;
            top: 85px;
            right: 0px;
            background: url('../../images/backgrounds/financial-fact.jpg');
            background-size: cover;
        }
    }

    section.assets-liabilities {
        width: 47.5%;
        height: 575px;
        float: left;
        margin-right: 50px;
        position: relative;
    }

    section.budget-planner {
        width: 47.5%;
        height: 575px;
        float: left;
        position: relative;

        .budget-plan-summary {
            width: calc(100% - 50px);
            height: 390px;
            margin-top: 30px;
            background-color: $white;
            border-radius: 10px;
            padding: 25px 20px 25px 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .pacing-section {
                margin-bottom: 40px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: center;
                position: relative;

                h2 {
                    color: #B3B3B3;
                    font-weight: 500;
                    flex-basis: 100%;
                }

                .bar {
                    width: 74%;
                    height: 45px;
                    background-color: #E6E6E6;
                    position: relative;

                    .pace {
                        height: 45px;
                        border-radius: 0px 30px 30px 0px;
                        position: absolute;
                        z-index: 10;

                        label.total {
                            color: $white;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 22px;
                            position: absolute;
                            top: 12px;

                            &.off-track {
                                color: $status-red;
                            }

                            &.on-track {
                                color: #7CD4B7;
                            }

                            &.inside {
                                color: $white;
                            }
                        }

                        &.off-track {
                            background-color: $status-red;
                        }

                        &.on-track {
                            background-color: #7CD4B7;
                        }

                        &.full {
                            border-radius: 0px;
                        }
                    }

                    label.bar-goal {
                        color: #262832;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px;
                        position: absolute;
                        top: 12px;
                        right: 15px;
                    }
                }

                .summary {
                    width: 100px;
                    height: 45px;
                    margin-left: 15px;
                    display: flex;
                    align-items: center;

                    &.mobile-version {
                        display: none;
                    }

                    &.off-track {
                        label {
                            color: $status-red;
                        }
                    }

                    &.on-track {
                        label {
                            color: #7CD4B7;
                        }
                    }

                    img {
                        width: 40px;
                        height: 40px;
                        margin-right: 5px;
                    }

                    label {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 14px;
                    }
                }

                .achievement-goal {
                    position: absolute;
                    width: 74%;
                    left: -5px;
                    top: 70px;
                    display: flex;
                    align-items: center;
                    padding: 5px 0 0 5px;

                    label {
                        font-size: 14px;
                        font-weight: 700;

                        span {
                            font-size: 14px;
                        }
                    }

                    .achievement.on-track { color: #7CD4B7; }
                    .achievement.off-track { color: $status-red; }

                    .goal {
                        margin: 0 0 0 auto;
                    }
                }
            }
        }
    }

    a.update {
        color: $brand-secondary;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        position: absolute;
        right: 5px;
        bottom: 0px;

        &:hover {
            text-decoration: none;
        }

        svg.arrow-icon {
            margin-left: 5px;
            position: relative;
            top: 8px;
        }
    }

    section.retirement-planner {
        width: 47.5%;
        height: 615px;
        float: left;
        margin-right: 50px;
        position: relative;

        .rp-summary {
            height: 480px;
            margin-top: 30px;
            background-color: $brand-primary;
            border-radius: 10px;

            .header {
                height: 60px;
                background-color: $brand-primary;
                color: $white;
                font-size: 24px;
                font-weight: 700;
                display: flex;
                justify-content: center;
                align-items: center;

                &:first-child {
                    border-radius: 10px 10px 0 0;
                }

                span {
                    color: $dataviz-1;
                }
            }

            .contributions {
                position: relative;
                background-color: $white;
                padding: 60px 0 45px 0;

                .bar {
                    width: calc(100% - 60px);
                    height: 35px;
                    background-color: #F4F6F8;
                    border-radius: 0 25px 25px 0;
                    margin: 0 30px;

                    .pace {
                        height: 35px;
                        z-index: 10;
                        background-color: $actual;
                        border-radius: 0 25px 25px 0;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        .progress-label {
                            color: $white;
                            font-weight: 700;
                            font-size: 22px;
                            padding: 0 10px 0 0;
                            z-index: 100;

                            &.adjust {
                                color: $brand-primary;
                                position: absolute;
                                left: 30px;
                                top: 95px;
                            }
                        }
                    }
                }

                .goal-line {
                    border-left: 5px solid $white;
                    height: 45px;
                    position: absolute;
                    bottom: 35px;
                    display: flex;
                    align-items: flex-end;
                    z-index: 20;

                    &.even {
                        bottom: 45px;
                        align-items: flex-start;

                        span {
                            top: -22px;
                        }
                    }

                    &:last-child {
                        height: 60px;
                        width: 1px;
                    }

                    &.minimum { color: $minimum; border-color: $minimum; }
                    &.target { color: $target; border-color: $target; }
                    &.stretch { color: $stretch; border-color: $stretch; }

                    span {
                        display: block;
                        position: relative;
                        top: 20px;
                        left: -35px;
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
            }

            .savings {
                width: 100%;
                height: 190px;
                position: relative;
                background-color: $white;
                border-radius: 0 0 10px 10px;

                label.actual {
                    position: absolute;
                    left: 0;
                    top: 80%;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 38px;
                    color: $actual;
                    width: 100%;
                    text-align: center;
                }

                img.arrow {
                    position: absolute;
                    top: 74%;
                    left: 28%;
                    transform-origin: center right;
                }
            }

            .legend {
                width: 100%;
                height: 40px;
                background-color: $brand-primary;
                border-radius: 0 0 10px 10px;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                .legend-item {
                    margin-right: 35px;
                    display: flex;
                    align-items: center;

                    &:last-child {
                        margin-right: 0;
                    }

                    div.circle {
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;

                        &.minimum { background-color: $minimum; }
                        &.target { background-color: $target; }
                        &.stretch { background-color: $stretch; }
                        &.actual { background-color: $actual; }
                    }

                    label {
                        margin-left: 10px;
                        color: $white !important;
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}


.section-default {
    width: 512px;
    height: 440px;
    background-repeat: no-repeat;
    background-size: 512px 440px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    border-radius: 10px;

    p {
        width: 100%;
        text-align: center;
        color: $white;
        font-size: 21px;
        font-weight: 500;
    }

    button.create {
        height: 36px;
        background-color: $brand-primary;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px !important;
        font-weight: 500 !important;
        font-size: 21px !important;
        line-height: 30px !important;
        color: $white;
        cursor: pointer;
        padding: 0 25px !important;
    }
}

@media (max-width: 1439px) {
    .dashboard {
        section.action-plan {
            position: static;
            //margin-top: 95px;

            h1 {
                margin: 25px 20px 5px 20px;
            }

            h2.sub {
                span.icon-button {
                    position: relative;
                    left: 10px;
                    top: -2px;
                }
            }

            h3 {
                display: none;
            }

            .help {
                display: none;
                background-color: $white;
                position: absolute;
                top: 0px;
                left: 0px;
                height: 70px;
            }

            .fp-summary-empty {
                display: none;
            }

            .tiers {
                margin: 25px 0 0 0;
                justify-content: flex-start;

                .tier {
                    width: 44%;
                    max-width: 200px;
                    max-height: 145px;
                    margin: 0 8px 15px 10px;

                    .metadata {
                        height: 20px;
                        margin: 12px 0;

                        img {
                            height: 15px;
                            margin-right: 5px;

                            &:nth-of-type(1) {
                                width: 20px;
                            }
                        }

                        h2 {
                            font-size: 14px;
                            line-height: 17px;
                            margin: 0;
                            position: relative;
                            top: -1px;
                        }
                    }

                    .pie {
                        height: 70px;

                        label {
                            font-size: 21px;
                            top: 28%;
                        }

                        svg.arrow-icon {
                            width: 18px;
                            height: 18px;
                            bottom: -22px;
                            right: 10px;
                        }
                    }
                }
            }
        }

        section.your-agenda, section.section-next-steps, .category-detail {
            width: auto;
            height: auto;
            border-radius: 0px;
            padding: 20px 10px 35px 10px;
            margin: 15px 0 0 0;
            float: none;

            h1 {
                margin-left: 10px;
            }

            h2 {
                font-size: 15px;
                margin: 20px 0 10px 30px;
            }

            .next-steps, .tasks {
                margin: 10px 0 0 0;

                &:first-child {
                    margin: 0 0 50px 0;
                }

                .next-step {
                    .faux-checkbox, img, .MuiButtonBase-root {
                        display: none;
                    }

                    .task {
                        font-size: 14px;
                        line-height: 14px;
                        max-width: 85%;
                    }

                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }

                &.plan-empty-state {
                    width: 300px;
                    height: 110px;
                    background-size: 300px 110px;
                }

                &.tasks-empty-state {
                    width: 300px;
                    height: 220px;
                    background-size: 300px 220px;
                }
            }
        }

        section.assets-liabilities {
            width: 100%;
            height: 800px;
            margin: 20px 0 0 0;
            padding: 1px 0 20px 0;
            float: none;
            background-color: $white;

            &.no-data {
                height: 385px;
                margin: 20px 0;
            }

            h1 {
                margin: 35px 20px 5px 20px;
            }

            a {
                display: block;
                height: 25px;
                margin: 0 0 5px 0;
            }

            h2 {
                width: 75%;
                display: block;
            }
        }

        section.budget-planner {
            width: 100%;
            height: 575px;
            float: none;
            background-color: $white;
            margin-top: -27px;
            padding-top: 1px;

            &.no-data {
                height: 475px;
            }

            &.no-plan {
                height: 375px;
            }

            h1 {
                margin: 35px 20px 5px 20px;
            }

            h2 {
                display: block;
                margin: 10px 25px 50px 40px;
            }

            .budget-plan-summary {
                width: auto;
                height: auto;
                margin: 0;
                border-radius: 0;
                padding: 0 10px;

                .pacing-section {
                    h2 {
                        font-size: 14px;
                        margin: 6px 0;
                        display: flex;
                        justify-content: space-between;

                        .summary {
                            width: 80px;
                            height: 20px;

                            img {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }

                    .bar {
                        width: 100%;
                        height: 50px;

                        .pace {
                            height: 50px;

                            label.total {
                                top: 14px;
                                font-size: 14px;
                            }
                        }

                        label.bar-goal {
                            top: 14px;
                            right: 10px;
                            font-size: 14px;
                        }
                    }

                    .summary {
                        display: none;

                        &.mobile-version {
                            display: flex;
                        }
                    }

                    .achievement-goal {
                        width: 99%;
                        padding-top: 15px;
                    }
                }
            }

            a.update {
                right: 20px;
                bottom: 40px;
                font-size: 16px;
                display: flex;
                align-items: center;

                svg {
                    width: 18px;
                    height: 18px;
                    top: 0 !important;
                }
            }
        }

        section.retirement-planner {
            width: 100%;
            height: 705px;
            float: none;
            background-color: $white;
            margin-top: 20px;
            margin-right: 0;
            padding-top: 1px;

            &.no-plan {
                height: 375px;
            }

            h1 {
                margin: 35px 20px 5px 20px;
                display: block;
            }

            h2 {
                display: block;
                margin: 10px 25px 25px 40px;
            }

            .rp-summary {
                width: auto;
                height: auto;
                margin: 0;
                border-radius: 0;
                padding: 0;
                background-color: $white;

                .contributions {
                    .bar {
                        margin: 0 0 0 30px;
                    }
                }

                .savings {
                    label.actual {
                        left: 0;
                    }
                }

                .legend {
                    margin: 0;

                    .legend-item {
                        margin-right: 15px;
                    }
                }
            }

            a.update {
                right: 20px;
                bottom: 40px;
                font-size: 16px;
                display: flex;
                align-items: center;

                svg {
                    width: 18px;
                    height: 18px;
                    top: 0 !important;
                }
            }
        }

        section.financial-fact {
            width: auto;
            height: 370px;
            margin: -100px 0 0 0;
            display: none;

            &.mobile-version {
                display: block;
                margin-top: 0;
            }

            background-color: $brand-primary;
            background-image: linear-gradient(165deg, $brand-primary 42%, rgba(38, 40, 50, 0) 105%), url('../../images/backgrounds/financial-fact.jpg');
            background-size: 100% 420px;
            background-position: bottom center;
            background-repeat: no-repeat;

            .fact {
                width: calc(100% - 30px);
                height: 100%;
                position: unset;
                border-radius: 0;
                padding: 37px 0 0 30px;
                color: $white;
                background-color: initial;

                p {
                    width: 80%;
                }
            }

            .background {
                display: none;
            }
        }

        .video-mobile {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
        }
    }

    .section-default {
        width: 300px;
        height: 220px;
        background-size: 300px 220px;
        margin: 25px auto;
    }
}
