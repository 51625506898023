@import '../../styles/vars';

.retirement-planner {
    .rp-header {
        position: relative;
        top: 0;
        width: 1073px;
        background: $brand-tertiary;

        h2 {
            a {
                color: $brand-primary;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }

                &.selected {
                    color: $brand-secondary;
                }
            }
        }

        h3 {
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            margin: 35px 0 5px 0;

            a {
                color: $brand-secondary;
            }
        }

        .help {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 10px;
            right: 0px;

            span {
                display: inline-block;
                margin: 0 10px 0 0;
                color: $brand-secondary;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            img {
                width: 40px;
                height: 40px;
                cursor: pointer;
            }
        }
    }

    .steps {
        min-height: 325px;
        margin: 40px 0 35px 0;
        display: flex;
        justify-content: space-between;

        .step {
            width: 15%;
            background-color: $brand-primary;
            border-radius: 10px;
            padding: 45px 20px;
            cursor: pointer;
            position: relative;

            .badge {
                position: absolute;
                right: -12px;
                top: -12px;
            }

            .step-metadata {
                label {
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 35px;
                    letter-spacing: 0.2em;
                    color: $brand-secondary;
                    margin: 0 0 15px 0;
                }

                h3 {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    color: $white;
                    margin: 0 0 10px 0;
                }

                p {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 17px;
                    color: $white;
                    margin: 0 0 35px 0;
                }

                .progress {
                    width: 150px;

                    .progress-bar {
                        height: 10px;
                        margin: 0 0 8px 0;

                        .pace {
                            height: 10px;
                        }
                    }

                    .progress-details {
                        label {
                            font-size: 12px;
                            line-height: 14px;
                            font-weight: 400;
                            color: $white;
                            letter-spacing: 0;
                        }
                    }
                }

                .updated-income-or-expenses {
                    display: none;
                }
            }

            img {
                display: none;
            }

            &.selected {
                width: 50%;
                background-color: $white;
                display: flex;
                padding: 45px 20px 35px 30px;
                cursor: auto;

                .step-metadata {
                    padding: 0 30px 0 0;

                    h3 {
                        color: $brand-primary;
                        margin: 0 0 15px 0;
                    }

                    p {
                        font-size: 16px;
                        line-height: 19px;
                        color: $black;
                        margin: 0 0 50px;
                    }

                    .progress {
                        width: 260px;

                        .progress-bar {
                            height: 15px;

                            .pace {
                                height: 15px;
                            }
                        }

                        .progress-details {
                            label {
                                font-size: 16px;
                                line-height: 19px;
                                font-weight: 400;
                                color: $brand-primary;
                            }
                        }
                    }

                    .updated-income-or-expenses {
                        display: flex;

                        label {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                            color: $black;
                            letter-spacing: inherit;
                            margin: -3px 0 0 20px;
                        }
                    }
                }

                img {
                    display: block;
                    width: 250px;
                    height: 250px;
                }
            }
        }
    }

    section {
        background-color: $white;
        border-radius: 10px;
        margin: 0 0 40px 0;

        header {
            padding: 25px 0 20px 0;
            margin: 0 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &.border {
                border-bottom: 1px solid #E6E6E6;

                &.collapsed {
                    border: none;
                }
            }
        }

        h3 {
            height: 35px;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: $brand-primary;
            display: flex;
            align-items: center;
            margin: 0 0 15px 0;

            svg {
                display: block;
                margin: 0;
                width: 35px;
                height: auto;
                cursor: pointer;
            }

            span {
                margin: 0 0 0 10px;
            }

            &.header {
                margin: 0;
            }
        }

        h4 {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            margin: 0 0 30px 0;
            color: $black;

            a {
                color: $brand-secondary;

                &.right {
                    margin: 0 0 0 auto;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                }
            }

            &.normal {
                display: block;
            }

            span {
                display: inline;
            }
        }

        a.lm-link {
            color: $brand-secondary;
            text-decoration: none;
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;

            img, svg {
                padding: 0 0 0 10px;
            }

            &:hover {
                text-decoration: underline;
            }

            &.add {
                margin-top: 15px;
            }

            &.close {
                color: $white;
                align-self: flex-end;
                position: relative;
                top: 10px;
            }
        }

        .learn-more-section {
            background-color: $brand-primary;
            padding: 40px 30px 20px 75px;
            color: $white;
            display: flex;
            position: relative;
            align-items: center;

            .info {
                width: 70%;
                margin: 0 45px 0 0;

                h4 {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    margin: 0 0 15px 0;
                    padding: 0;
                    color: $white;
                }

                p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;

                    a {
                        color: $brand-secondary;
                    }
                }
            }

            img.illustration {
                width: 205px;
                height: 205px;
                background-color: $white;
                border-radius: 10px;
                margin: 0 0 15px 0;
            }
        }

        .subsection {
            padding: 30px 30px 40px 30px;
            color: $brand-primary;
            border-radius: 0 0 10px 10px;
            position: relative;

            .loading {
                position: static !important;
            }

            form {
                display: flex;
                flex-wrap: wrap;

                &.narrow {
                    flex-direction: column;
                }

                fieldset {
                    border: none;
                    width: 30%;
                    height: 65px;
                    margin: 0 95px 30px 0;
                    padding: 0;
                    position: relative;

                    label {
                        display: flex;
                        align-items: center;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: $brand-primary;
                        margin: 0 0 10px 0;

                        span {
                            display: block;
                            margin-left: 10px;
                        }
                    }

                    .needs-updating {
                        top: 40px;
                        left: -20px;
                    }

                    input {
                        width: 100%;
                        border: 1px solid #26273F;
                        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
                        border-radius: 5px;
                        padding: 8px 10px;
                        font-family: Arial;
                        font-size: 16px;
                        line-height: 19px;

                        &::placeholder {
                            font-size: 16px;
                            line-height: 19px;
                            color: #B3B3B3;
                        }
                    }
                }
            }

            &.alt {
                background-color: #F4F6F8;

                a#retirementAccounts, a#goalsAndProgress {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            &.eb {
                margin-top: -50px;
                position: relative;
                height: auto;
                min-height: 525px;
                padding-bottom: 20px;

                h3 {
                    margin-top: 35px;
                }

                h4 {
                    width: 45%;
                }
            }

            &.legend {
                width: 100%;
                background-color: $brand-primary;
                border-radius: 0 0 10px 10px;
                padding: 20px 0;
                display: flex;
                justify-content: center;
                align-items: center;

                .legend-item {
                    margin-right: 60px;
                    display: flex;
                    align-items: center;

                    &:last-child {
                        margin-right: 0;
                    }

                    div.circle {
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;

                        &.minimum { background-color: $minimum; }
                        &.target { background-color: $target; }
                        &.stretch { background-color: $stretch; }
                        &.actual { background-color: $actual; }
                    }

                    label {
                        margin-left: 10px;
                    }
                }
            }

            .assets-list {
                list-style-type: none;
                padding: 0;
                margin: 0 0 30px 0;

                li {
                    width: 50%;
                    display: flex;
                    align-items: center;
                    margin: 0 0 20px 0;

                    label {
                        padding: 0 20px;
                        position: relative;
                        top: -1px;
                    }

                    span.value {
                        position: relative;
                        top: -1px;
                        font-weight: 700;
                    }
                }
            }

            .no-assets {
                background-color: $white;
                border-radius: 10px;
                display: flex;
                align-items: center;
                padding: 10px 15px;
                position: relative;
                width: 75%;
                margin: 0 0 40px 0;

                span.msg {
                    padding: 0 30px 0 15px;
                }

                a {
                    color: $brand-secondary;
                    margin: 0 0 0 auto;
                }

                span.info {
                    position: absolute;
                    right: -30px;
                }
            }

            .cashflow-table {
                .account, .amount, .source {
                    width: 210px;
                }
            }

            .goals-outlook-fields, .desired-income-percentages {
                display: flex;

                &:nth-child(5) {
                    margin: 40px 0 0 0;
                }

                &.half {
                    width: 50%;
                }

                .field {
                    display: flex;
                    flex-direction: column;
                    margin: 0 35px 0 0;

                    label {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        color: $brand-primary;
                        display: flex;
                        align-items: center;
                        margin: 0 0 8px 0;

                        span.info {
                            margin-left: 10px;
                        }

                        &.center { justify-content: center; }
                    }

                    input, select {
                        border: 1px solid $brand-primary;
                        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
                        border-radius: 5px;
                        width: 135px;
                        padding: 8px 10px;
                        color: $brand-primary;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;

                        &.disabled {
                            border: none;
                            background-color: #F4F6F8;
                        }

                        &.bold {
                            font-weight: 700;
                            background-color: $white;
                            width: 120px;
                        }

                        &.minimum, &.target, &.stretch { text-align: center; }
                    }

                    select {
                        width: 85px;
                    }

                    .field-extra {
                        display: flex;
                        align-items: center;
                        position: relative;

                        .needs-updating {
                            top: 0;
                            left: 0;
                            position: relative;
                            margin-right: 10px;
                        }

                        input {
                            margin-right: 10px;
                        }

                        a {
                            color: $brand-secondary;
                        }
                    }

                    a {
                        color: $brand-secondary;
                    }

                    &.savings-goals {
                        width: 110px;
                        margin: 0 35px 0 190px;
                    }

                    &.center {
                        width: 120px;
                        margin-right: 60px;

                        label {
                            text-align: center;
                            width: 120px;
                            padding: 0 10px;
                        }
                    }

                    .sub-fields {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;

                        .sub-field {
                            width: 42%;
                            margin: 15px 0 0 0;

                            &.third {
                                width: 23%;
                                margin-right: 35px !important;
                            }

                            &:nth-child(odd) {
                                margin-right: 50px;
                            }

                            label.sub {
                                width: 135px;
                                margin: 0 0 10px 45px;
                                font-size: 14px;
                                font-weight: 400;
                            }

                            .field-extra {
                                display: flex;
                                align-items: center;

                                input {
                                    background-color: $white;
                                    margin: 0 0 0 15px;

                                    &[type=checkbox] {
                                        width: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .desired-income-percentages {
                background-color: $white;
                padding: 30px 0px 20px 30px;
                margin: -28px 0 0 -29px;
                width: 49.8% !important;

                h4 {
                    width: 100%;
                    margin: 0;
                }
            }

            .charts {
                display: flex;
                margin: 85px 0 0 0;
                width: 100%;
                height: 300px;

                h5 {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    color: $brand-primary;
                    margin: 0 auto 30px auto;
                    text-align: center;

                    span {
                        color: $actual;
                    }
                }

                .savings-rate {
                    width: calc(45% - 105px);
                    height: 300px;
                    padding: 0 70px;
                    position: relative;

                    label.actual {
                        position: absolute;
                        left: 0;
                        top: 70%;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 38px;
                        color: $actual;
                        width: 100%;
                        text-align: center;
                    }

                    img.arrow {
                        position: absolute;
                        top: 65%;
                        left: 27%;
                        transform-origin: center right;
                    }
                }

                .contributions {
                    width: 55%;
                    height: 300px;
                    position: relative;

                    .bar {
                        width: 100%;
                        height: 50px;
                        background-color: #F4F6F8;
                        border-radius: 0 10px 10px 0;
                        margin-top: 141px;
                        z-index: 1;

                        .pace {
                            height: 50px;
                            z-index: 10;
                            background-color: $actual;
                        }
                    }

                    .progress-label {
                        color: $actual;
                        font-weight: 700;
                        font-size: 22px;
                        line-height: 26px;
                        text-align: right;
                        margin: 5px 0 0 0;
                        z-index: 100;
                        position: relative;
                    }

                    .goal-line {
                        border-left: 4px solid $white;
                        height: 90px;
                        position: absolute;
                        bottom: 50px;
                        display: flex;
                        align-items: flex-end;

                        &.even {
                            bottom: 90px;
                            align-items: flex-start;

                            span {
                                top: -18px;
                            }
                        }

                        &:last-child {
                            height: 120px;
                        }

                        &.minimum { color: $minimum; border-color: $minimum; }
                        &.target { color: $target; border-color: $target; }
                        &.stretch { color: $stretch; border-color: $stretch; }

                        span {
                            display: block;
                            position: relative;
                            top: 18px;
                            left: -11px;
                        }
                    }
                }
            }

            .retirement-planner-table {
                width: 106%;
                margin: 40px 0 0 -30px;
                color: $brand-primary;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                border-collapse: separate;
                border-spacing: 0;
                position: relative;

                &::-webkit-scrollbar {
                    display: none;
                }

                &.scroll {
                    max-height: 588px;
                    display: block;
                    overflow-y: scroll;
                    width: 105.9%;
                }

                &.savings-goals {
                    thead {
                        tr {
                            td {
                                border: none;
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                padding: 0;
                            }
                        }
                    }
                }

                thead {
                    tr {
                        th {
                            position: sticky !important;
                            top: 0;
                            z-index: 10;
                            background-color: $white;
                        }

                        td,th {
                            padding: 0 0 10px 0;
                            text-align: center;
                            font-size: 16px !important;
                            line-height: 19px !important;
                            color: $brand-primary !important;
                            border-bottom: 1px solid #B3B3B3;

                            &.end-value {
                                label {
                                    max-width: 140px;
                                    display: inline-block;
                                }
                            }

                            &.year, &.savings-rate {
                                vertical-align: bottom;
                            }
                        }
                    }

                }

                tbody {
                    tr {
                        &.odd {
                            background-color: #F4F6F8;
                        }

                        &:hover {
                            td {
                                font-weight: 700;

                                &.end-value {
                                    a {
                                        display: block;
                                    }
                                }
                            }
                        }

                        &.editing, &.adding {
                            input {
                                width: 100px;
                                text-align: center;
                            }

                            .end-value {
                                a { top: 30px; }
                            }
                        }

                        td {
                            text-align: center;
                            padding: 20px 0;

                            &:first-child {
                                margin-left: 0;
                            }

                            &.description {
                                padding: 20px;
                            }

                            &.year {
                                select {
                                    width: 80px !important;
                                }
                            }
                        }
                    }
                }

                .savings-goals {
                    width: 31%;
                    text-align: right;
                    padding: 0 15px 10px 0;
                }

                .edit {
                    width: 30%;
                    text-align: right;
                    padding: 0 90px !important;

                    a {
                        color: $brand-secondary;
                    }
                }

                .year {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    width: 11%;
                    position: relative;

                    svg {
                        position: absolute;
                        top: 26px;
                        left: 15px;
                        cursor: pointer;
                    }

                    &.no-partner {
                        width: 16%;
                    }
                }

                .age, .partnerAge {
                    font-size: 18px;
                    line-height: 22px;
                    width: 10%;

                    &.no-partner {
                        width: 15%;
                    }
                }

                .minimum, .target, .stretch, .low, .medium, .high {
                    width: 15%;
                    font-weight: 700;
                }

                .description, .dummy {
                    width: 19%;
                }

                .income {
                    width: 8%;
                }

                .contributions {
                    width: 12%;
                }

                .savings-rate, {
                    width: 15%;
                }

                .growth-rate, {
                    width: 10%;
                }

                .end-value {
                    width: 24%;
                    position: relative;

                    a {
                        display: none;
                        position: absolute;
                        right: 35px;
                        top: 22px;
                        color: $brand-secondary;
                        font-weight: 400;
                    }
                }

                .dummy {
                    text-align: left;
                }

                .bold {
                    font-weight: 700;
                }

                .green {
                    color: #7CD4B7;
                }

                .red {
                    color: $status-red;
                }

                input, .faux-input, select {
                    border: 1px solid $brand-primary;
                    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
                    border-radius: 5px;
                    width: 135px;
                    padding: 8px 10px;
                    color: $brand-primary;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    margin: 0 auto;

                    &.disabled {
                        border: none;
                        background-color: #F4F6F8;
                    }

                    &.bold {
                        font-weight: 700;
                        background-color: $white;
                        width: 120px;
                    }

                    &.minimum, &.target, &.stretch { text-align: center; }
                    &.low { background-color: $lowest; color: $white !important; text-align: center; }
                    &.medium { background-color: $medium; color: $white !important; text-align: center; }
                    &.high { background-color: $highest; color: $white !important; text-align: center; }
                }
            }

            .reset {
                width: 45%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin: 30px 0 0 0;

                a {
                    color: $brand-secondary;
                    margin-right: 10px;
                }
            }

            .nest-egg-chart {
                width: 50%;
                height: calc(100% - 2px);
                background-color: $white;
                position: absolute;
                top: 2px;
                right: 0;
                border-radius: 0 0 10px 0;

                .chart-header {
                    display: flex;
                    margin: 60px 0 40px 50px;

                    .need {
                        width: 130px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-right: 20px;

                        &:first-child {
                            margin-left: 20px;
                        }

                        label {
                            text-align: center;
                            font-size: 16px;
                            font-weight: 700;
                            margin: 0 0 20px 0;
                        }

                        input, .need-value {
                            width: 100px;
                            padding: 8px 15px;
                            color: $white !important;
                            font-size: 16px;
                            font-weight: 700;
                            border: none;
                            box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.05);
                            border-radius: 5px;
                            text-align: center;
                            &.low { background-color: $lowest; }
                            &.medium { background-color: $medium; }
                            &.high { background-color: $highest; }
                        }
                    }
                }

                .chart {
                    .recharts-cartesian-axis-tick-value {
                        fill: #B3B3B3;
                    }
                }
            }


            .historical-progress-chart {
                width: 100%;
                padding: 0;
                border: 1.5px solid #CFCFCF;
                border-radius: 10px;

                h3 {
                    padding: 30px 0 0 30px;
                    margin: 0;
                }

                .chart {
                    width: 100%;
                    height: 385px;
                    margin: 0;

                    text[orientation="left"] {
                        fill: #A1E5E9;
                        font-size: 16px;
                        font-weight: 700;
                    }

                    text[orientation="right"] {
                        fill: $dataviz-3;
                        font-size: 16px;
                        font-weight: 700;
                    }

                    text[orientation="bottom"] {
                        fill: #9D9D9D;
                        font-size: 16px;
                    }
                }

                .legend {
                    width: 100%;
                    background-color: $brand-primary;
                    border-radius: 0px 0px 10px 10px;
                    padding: 20px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .legend-item {
                        display: flex;
                        align-items: center;

                        .icon {
                            width: 15px;
                            height: 15px;
                            margin-right: 10px;
                        }

                        label {
                            font-size: 16px;
                            margin-right: 10px;
                        }

                        span {
                            font-size: 16px;
                            color: $white;
                        }

                        &.value {
                            margin-right: 50px;

                            .icon {
                                background-color: #A1E5E9;
                            }

                            label {
                                color: #A1E5E9;
                            }
                        }

                        &.contributions {
                            .icon {
                                background-color: $dataviz-3;
                                border-radius: 50%;
                            }

                            label {
                                color: $dataviz-3;
                            }
                        }
                    }
                }
            }

            .annualized-average {
                border: 1px solid #B3B3B3;
                border-radius: 10px;
                margin: 15px 0 0 -20px;
                display: flex;
                background-color: #F4F6F8;
                padding: 10px 0;
                width: 103%;

                .year, .income, .contributions, .savings-rate, .growth-rate, .end-value {
                    text-align: center;
                    font-weight: bold;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.green {
                        color: #7CD4B7;
                    }

                    &.red {
                        color: #EB535C;
                    }
                }

                .year {
                    width: 12.5%;
                    font-size: 14px;
                }

                .income {
                    width: 11.5%;
                }

                .contributions {
                    width: 14.5%;
                }

                .savings-rate {
                    width: 20%;
                }

                .growth-rate {
                    width: 12.5%;
                }

                .end-value {
                    width: 28%;
                    position: relative;
                    left: 15px;
                }
            }

            .table-footer-actions {
                margin: 40px 0 0 0;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span {
                    margin: 0 0 0 auto;
                    a { color: $brand-secondary; }
                }
            }

            .logged-out-lightbox {
                width: 100%;
                height: 100%;
                z-index: 1000;
                background: rgba(38, 39, 63, 0.40);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                display: flex;
                justify-content: center;
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 0 0 10px 10px;

                .faux-dialog {
                    width: 290px;
                    height: 170px;
                    background: $white;
                    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
                    border-radius: 10px;
                    margin-top: 150px;
                    padding: 25px 30px;

                    h3 {
                        text-align: center;
                        font-size: 24px;
                        font-weight: 700;
                        margin: 25px 0 50px 0;
                    }

                    .buttons {
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;

                        a {
                            width: 90px;
                            height: 40px;
                            border-radius: 20px;
                            border: 1.5px solid $brand-secondary;
                            background: $white;
                            box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.15);
                            color: $brand-secondary;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-decoration: none;

                            &.primary {
                                color: $white;
                                background: $brand-secondary;
                                box-shadow: 2px 2px 2px 0px #3CA49D;
                            }
                        }
                    }
                }
            }
        }

        &.historical-progress {
            position: relative;
            min-height: 300px;

            &.collapsed {
                min-height: auto;
            }
        }
    }

    .nav-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
            display: block;
            font-weight: 500;
            font-size: 21px;
            line-height: 30px;
            color: $brand-secondary;
        }

        button.primary {
            margin-left: 25px;
        }
    }

    .needs-updating {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $status-red;
        position: absolute;
    }

    .MuiButtonBase-root {
        padding: 0;
        color: $brand-primary;
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
        color: $brand-secondary;
    }
}

.rp-intro-dialog {
    display: flex;
    justify-content: space-between;

    .step-wrapper {
        width: 30%;

        .step {
            width: 100%;
            text-align: left;
            margin-top: 30px;

            img {
                box-shadow: 0px 4px 4px rgba(207, 207, 207, 0.15);
                border-radius: 10px;
                background-color: $white;
                width: 100%;
            }

            label {
                font-weight: 700;
                font-size: 15px;
                line-height: 35px;
                letter-spacing: 0.2em;
                color: $brand-secondary;
                text-transform: uppercase;
                display: block;
                padding: 0 0 0 20px;
            }

            span {
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: $brand-primary;
                display: block;
                padding: 0 0 0 20px;
                width: 77%;
            }
        }

        .next {
            width: 5%;
            position: relative;
            display: flex;
            left: 106%;
            top: -22px;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;

            img {
                width: 20px;
                height: 20px;
                position: relative;
                bottom: 50px;
            }
        }
    }
}

.MuiDialog-paper {
    h1 {
        width: 60% !important;
        margin: 0 auto !important;
    }

    h2 {
        font-weight: 400 !important;
    }

    .retirement-dialog-actions {
        button.primary {
            margin: 50px 0 0 auto !important;

            &.center {
                float: none;
                text-align: center;
                margin: 30px auto !important;
            }
        }
    }
}

.minimum { color: $minimum !important; }
.target { color: $target !important; }
.stretch, .optimal { color: $stretch !important; }
.actual { color: $actual !important; }
.low { color: $lowest !important; }
.medium { color: $medium !important; }
.high { color: $highest !important; }

@media (max-width: 1439px) {
    .retirement-planner {
        .rp-header {
            h1, h2.sub.step-nav, h3 {
                display: none;
            }

            .help {
                display: none;
            }
        }

        .steps {
            width: 100%;
            min-height: 450px;
            justify-content: center;

            .step {
                display: none;

                &.selected {
                    display: flex;
                    flex-direction: column;
                    width: calc(87.5% - 40px);
                    padding: 20px;

                    .step-metadata {
                        padding: 0 25px 0 0;

                        p {
                            margin: 0
                        }

                        .updated-income-or-expenses {
                            display: none;
                        }
                    }

                    .progress {
                        width: 95%;
                    }

                    .updated-income-or-expenses {
                        display: flex;
                        justify-content: space-between;
                        font-size: 10px;

                        span {
                            position: relative;
                            top: 2px;
                            left: 10px;
                        }

                        label {
                            width: 90%;
                        }
                    }
                }
            }
        }

        .arrows {
            display: flex;
            align-self: flex-start;
            width: 50%;
            margin: -20px auto 40px auto;
            justify-content: center;
            height: 40px;

            .arrow-nav {
                width: 40px;
                height: 40px;
                cursor: pointer;

                &.back {
                    transform: rotateY(180deg);
                    margin-right: 15px;
                }
            }
        }

        section {
            max-width: 100%;
            overflow: hidden;
            border-radius: 0;

            header {
                margin: 0 20px;
                padding: 25px 0 20px 0;
            }

            h3 {
                font-size: 21px;
                height: auto;

                .icon-button {
                    position: relative;
                    top: 1px !important;
                    min-width: 15px;
                }
            }

            h4 {
                font-size: 12px;
                display: block;
            }

            .learn-more-section {
                padding: 20px;
                flex-direction: column;

                .info {
                    width: 100%;
                    margin: 0;

                    p {
                        margin: 0 0 16px 0;
                    }
                }
            }

            .subsection {
                padding: 20px;
                position: relative;

                &.alt {
                    border-radius: 0;
                }

                &.achievements-goals {
                    padding: 0;

                    h4 {
                        padding: 20px;
                    }
                }

                form {
                    padding: 0 20px;

                    fieldset {
                        width: 100%;

                        label {
                            font-weight: 700;

                            .icon-button {
                                position: relative;
                                top: 1px;
                            }
                        }

                        input {
                            width: 225px;
                        }
                    }
                }

                .assets-list {
                    li {
                        width: 90%;

                        label {
                            width: 35%;
                        }
                    }
                }

                .add-row {
                    position: inherit;
                }

                h3 {
                    span {
                        position: relative;
                        top: -3px;
                    }
                }

                h4 {
                    a.right {
                        display: block;
                        margin-top: 10px;
                        font-size: 14px;
                    }
                }

                .goals-outlook-fields, .desired-income-percentages {
                    flex-direction: column;

                    .field {
                        margin: 0 0 25px 0;

                        input[type='text'] {
                            width: 225px;
                        }

                        .value-retirement-accounts {
                            display: block;
                            font-size: 14px;

                            input {
                                margin: 0 0 20px 0;
                            }
                        }

                        .sub-fields {
                            .sub-field {
                                width: 75%;
                            }
                        }
                    }
                }

                .desired-income-percentages {
                    width: 100% !important;
                    padding: 0 30px;

                    span.info {
                        display: none;
                    }
                }

                .charts {
                    height: auto;
                    margin: 40px 0 0 0;
                    flex-direction: column;

                    h5 {
                        margin: 0 auto 20px auto;
                    }

                    .savings-rate, .contributions {
                        width: 100%;
                        padding: 0;

                        label.actual {
                            top: 69%;
                            left: 50%;
                            transform: translate(-50%, 0%);
                        }

                        img.arrow {
                            position: absolute;
                            margin: -103px 0 0 -115px;
                            top: auto;
                        }

                        .goal-line {
                            &:last-child {
                                span {
                                    left: -70px;
                                }
                            }
                        }
                    }
                }

                &.legend {
                    border-radius: 0;
                    flex-direction: column;
                    align-items: flex-start;

                    .legend-item {
                        margin: 0 0 20px 60px;

                        &:last-child {
                            margin: 0 0 0 60px;
                        }
                    }
                }

                .savings-goals {
                    label {
                        color: $brand-primary;
                        font-size: 14px;
                        font-weight: 700;
                    }

                    a {
                        position: relative;
                        left: 20px;
                        color: $brand-secondary;
                        font-size: 14px;
                    }

                    .goal-types {
                        display: flex;
                        margin-top: 15px;

                        .goal-type {
                            input {
                                width: 85px;
                                height: 33px;
                                border-radius: 5px;
                                background: $white;
                                border: 1px solid $white;
                                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
                                margin: 10px 10px 0 0;
                                text-align: center;
                                font-size: 12px;
                                font-weight: 700;
                            }
                        }
                    }
                }

                .goals-header {
                    .goal {
                        display: flex;
                        font-size: 14px;
                        font-weight: 700;
                        margin: 0 auto 20px auto;
                        width: 69%;

                        img {
                            margin-right: 10px;
                            position: relative;
                            top: 1px;
                        }
                    }
                }

                .mobile-table {
                    .header {
                        width: 100%;
                        margin: 0;
                        padding: 0 0 15px 0;
                        display: flex;

                        .header-col {
                            text-align: center;
                            font-size: 12px;
                            font-weight: 700;
                            color: $brand-primary;
                        }
                    }

                    .body {
                        .row {
                            background-color: $white;
                            filter: drop-shadow(0px 0px 4px rgba(38, 40, 50, 0.20));
                            margin: 0 0 10px 0;
                            padding: 10px 0;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            position: relative;

                            svg {
                                position: absolute;
                                left: 5px;
                                bottom: 5px;
                            }

                            .body-col {
                                text-align: center;
                                font-size: 12px;
                                font-weight: 400;
                                color: $brand-primary;

                                .nest-egg {
                                    width: 65px;
                                    color: $white;
                                    text-align: center;
                                    font-size: 10px;
                                    font-weight: 700;
                                    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
                                    border-radius: 5px;
                                    padding: 4px 0;

                                    &.low { background-color: $lowest; color: $white !important; }
                                    &.medium { background-color: $medium; color: $white !important; }
                                    &.high { background-color: $highest; color: $white !important; }
                                }
                            }

                            .expand-mobile {
                                width: 100%;
                                background-color: #F4F6F8;
                                display: flex;
                                flex-wrap: wrap;
                                margin: 10px 0 -10px 0;
                                padding: 10px 0 10px 25px;

                                .expand-row {
                                    width: 50%;
                                    margin: 0 0 15px 0;
                                    color: $brand-primary;
                                    font-size: 10px;

                                    &.full {
                                        width: 100%;
                                    }

                                    &:last-child {
                                        margin: 0;
                                    }

                                    label {
                                        color: #B3B3B3;
                                        font-weight: 700;

                                        &.bold {
                                            display: block;
                                            width: 100%;
                                            margin: 0 0 10px 0;
                                            color: $brand-primary;
                                            font-size: 14px;
                                        }
                                    }

                                    input, select {
                                        width: 135px;
                                        border-radius: 5px;
                                        border: 1px solid #26273F;
                                        background: #FFF;
                                        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
                                        padding: 8px 10px;
                                    }

                                    span.year {
                                        font-size: 14px;
                                    }

                                    span.green {
                                        color: #7CD4B7;
                                    }

                                    span.red {
                                        color: $status-red;
                                    }
                                }

                                a {
                                    margin: 0 18px 0 auto;
                                    color: $brand-secondary;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }

                &.eb {
                    margin-top: 0;

                    h4 {
                        width: 100%;
                    }
                }

                .reset {
                    width: 100%;
                    margin: 0 0 20px 0;
                    justify-content: flex-start;
                }

                .sub-field.third {
                    width: 20% !important;

                    select {
                        padding: 8px 2px;
                        width: 75px;
                    }
                }

                .nest-egg-chart {
                    position: relative;
                    width: calc(100% + 40px);
                    margin: 0 0 -20px -20px;
                    border-radius: 0;
                    height: 485px;

                    .chart-header {
                        padding: 20px 0 0 63px;
                        width: calc(100% - 78px);
                        margin: 0;

                        .need {
                            min-width: 33%;
                            margin: 0;
                            justify-content: space-between;

                            &:first-child {
                                margin: 0;
                            }

                            label {
                                font-size: 10px !important;
                            }

                            input, .need-value {
                                color: $white !important;
                                font-size: 10px !important;
                                width: 54px !important;
                                padding: 8px !important;
                            }
                        }
                    }
                }

                .logged-out-lightbox {
                    border-radius: 0;

                    .faux-dialog {
                        width: 250px !important;
                        height: 200px !important;
                    }
                }

                .historical-progress-chart {
                    width: calc(100% + 40px);
                    margin: 0 0 30px -20px;
                    border: none;

                    .legend {
                        border-radius: 0;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 35px 0 15px 45px;

                        .legend-item {
                            margin: 0 0 20px 0 !important;

                            span {
                                display: none;
                            }
                        }
                    }
                }

                .table-footer-actions {
                    span {
                        display: none;
                    }
                }
            }
        }

        .nav-buttons {
            margin: 0 0 115px 0;
            justify-content: center;

            a {
                font-size: 16px;
                margin: 0 10px 0 0;
            }

            button {
                font-size: 16px !important;
                margin: 0 !important;
                padding: 5px 15px !important;
            }
        }
    }
}
