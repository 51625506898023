@import '../../styles/vars';

.account {
    h1 {
        margin: 0 0 40px 0;
    }

    .form-wrapper {
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 50px;
        margin: 0;

        .auth-apps {
            display: flex;
            justify-content: space-around;

            img {
                width: 100px;
                border-radius: 10px;
                border: 1px solid #585757;
            }
        }

        form {
            width: 400px;
            height: auto;
            margin: 0 0 50px 0;

            fieldset {
                height: auto;

                label {
                    font-size: 16px;

                    &.expiration {
                        color: $status-red;
                    }

                    b {
                        color: $brand-primary !important;
                    }
                }

                ul {
                    li {
                        color: $brand-primary;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        width: 85%;
                    }

                    &.expiration {
                        color: $status-red;

                        li {
                            color: $status-red;
                        }
                    }
                }
            }

            &:nth-child(odd) {
                margin: 0 125px 50px 0;
            }

            input[type=submit].edit-account {
                margin-top: 50px;
            }
        }
    }
}

@media (max-width: 1439px) {
    .account {
        h1 {
            margin: 25px 20px;
        }

        .form-wrapper {
            margin: 0;
            padding: 25px 0;

            form {
                width: calc(100% - 20px);
                margin: 0 0 25px 0 !important;

                fieldset {
                    .ReactPasswordStrength {
                        width: 97%;
                    }
                }
            }

            .auth-apps {
                flex-wrap: wrap;
                justify-content: flex-start;

                img {
                    display: block;
                    margin: 0 25px 25px 0;
                }
            }
        }
    }
}
