@import '../../styles/vars';

.icon-button {
    width: 15px;
    height: 15px;
    border: 1px solid #B3B3B3;
    border-radius: 50%;
    background-color: #B3B3B3;
    color: $brand-tertiary;
    font-size: 12px;
    line-height: 14px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    text-align: center;

    &:hover {
        color: $brand-tertiary;
        background-color: $brand-secondary;
        border: 1px solid $brand-secondary;
    }

    &.remove {
        left: 0px;
        bottom: 0px;
        top: 5px;
        width: 30px;
    }
}

.MuiTooltip-tooltip {
    width: 175px !important;
    font-size: 16px !important;
    background: $brand-primary !important;
    color: $white !important;
    padding: 15px !important;
    border: 1px solid $brand-primary !important;
    border-radius: 10px !important;
    line-height: 20px !important;

    .MuiTooltip-arrow {
        color: $brand-primary !important;
    }

    span {
        display: inline-block;
    }
}

div[role=tooltip] {
    z-index: 1000000000 !important;
}
