.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 25px;
    background: rgba(255, 255, 255, 0.3);
    line-height: 0;
    color: #207dff;
    > div {
        background-color: #207dff;
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        opacity: 0.75;
        border-radius: 2px;
        animation: 1s ease 0s infinite normal none running pulse-loader;

        &:nth-child(2) {
            animation-delay: 100ms;
        }

        &:nth-child(3) {
            animation-delay: 200ms;
        }
    }
}

@keyframes pulse-loader {
    30% {
        transform: scale(1);
        opacity: 0.75;
    }
    50% {
        transform: scale(0.8);
    }
    70% {
        transform: scale(1.2);
        opacity: 1;
    }
    90% {
        transform: scale(1);
        opacity: 0.75;
    }
}
