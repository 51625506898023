@import '../../styles/vars';

.login {
    h1 {
        margin: 0 0 40px 0;
    }

    .form-wrapper {
        div.forgot-password {
            margin: 25px auto;
            text-align: center;
            width: 50%;

            a {
                color: $brand-secondary;
                font-size: 16px;
            }
        }

        img {
            display: block;
            margin: 25px auto;
            width: 150px;
        }
    }

    input.text.totp {
        width: 200px;
        padding: 8px 10px;
        background-color: $white;
        border: 1px solid #26273F;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $brand-primary;

        ::placeholder {
            color: #B3B3B3;
        }
    }

    input.primary.totp {
        display: block;
        margin: 25px 0 0 0;
    }
}

@media (max-width: 1439px) {
    .login {
        min-height: 735px;

        &.twofa {
            min-height: 350px;
        }

        h1 {
            margin: 25px 20px;
        }

        p {
            padding: 0 20px;
        }

        form {
            margin: 0 20px;
        }

        .form-wrapper {
            height: auto !important;
        }
    }
}
