@import '../../styles/vars';

.login-signup-header {
    width: 100%;
    height: 225px;
    background-color: $brand-primary;

    .subhead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 40px 0 25px;

        img {
            width: 70px;
            height: 70px;
        }

        a.primary {
            width: 50px;
            height: 25px;
            box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
            border-radius: 20px !important;
            padding: 5px 25px !important;
            font-weight: 500;
            font-size: 18px !important;
            line-height: 23px !important;
            font-family: 'Jost' !important;
            text-transform: unset !important;
            cursor: pointer;
            border: 1px solid $brand-secondary !important;
            background-color: $brand-secondary !important;
            color: $white;
            text-decoration: none;
        }
    }

    h1 {
        color: $white;
        font-size: 65px;
        font-style: normal;
        font-weight: 700;
        line-height: 85px;
        padding-left: 145px;
    }
}

.signup {
    width: 100% !important;
    min-height: 500px !important;
    border-radius: 0 !important;
    background-color: $white !important;
    padding: 35px 0 0 0 !important;

    &.benefit {
        min-height: 900px !important;
    }

    &.success {
        min-height: 250px !important;
        padding: 70px 0 0 0 !important;
    }

    h2 {
        color: #26273F;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
        text-align: center;
        width: 70%;
        max-width: 1050px;
        margin: 0 auto;
    }

    p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        width: 320px;
        margin: 35px auto;

        a {
            text-decoration: none;
            color: $brand-secondary;
            font-weight: 700;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .form-wrapper {
        margin: 0 auto;
        padding: 5px 0 0 0;

        form {
            margin: 0 auto;
            background-color: $white;
            padding: 0;
            text-align: center;
            width: 320px;

            fieldset {
                margin-bottom: 0;

                &.first {
                    margin-bottom: 80px;
                }

                label {
                    text-align: left;
                    font-weight: bold;
                    font-size: 18px;
                }

                input {
                    width: 300px;
                    border-radius: 5px;
                    border: 1px solid #26273F;
                    background: #FFF;
                    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .ReactPasswordStrength {
                    border: none;
                    width: 320px;

                    input[type=password] {
                        width: 320px;
                    }

                    .ReactPasswordStrength-strength-bar {
                        height: 5px;
                    }

                    .ReactPasswordStrength-strength-desc {
                        margin: 0;
                        padding: 10px;
                    }
                }
            }

            p {
                font-size: 14px;
                width: 335px;
            }
        }
    }
}

@media (max-width: 1439px) {
    .login-signup-header {
        h1 {
            padding: 0;
            margin: 20px auto;
            text-align: center;
        }

        &.benefit {
            h1 {
                font-size: 42px;
            }
        }
    }

    .signup {
        top: 0 !important;

        h2 {
            width: 85%;
            font-size: 20px;
        }

        .form-wrapper {
            height: auto;
            min-height: 650px;

            form {
                .ReactPasswordStrength {
                    width: 99%;

                    input[type=password] {
                        width: 100%;
                    }

                    .ReactPasswordStrength-strength-desc {
                        position: relative;
                        padding: 0;
                    }
                }
            }
        }
    }
}
