@import '../../styles/vars';

.back-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $brand-secondary;
    text-decoration: none;
    display: block;
    cursor: pointer;
}
