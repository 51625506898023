@import '../../styles/vars';

.syncing {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100000;
    width: 100%;
    height: 115px;
    background: #7CD4B7;
    display: flex;
    justify-content: center;
    align-items: center;

    div.status {
        display: flex;
        align-items: center;

        img {
            width: 35px;
            height: 35px;
            filter: brightness(0.85);
            margin-right: 20px;
            animation: spin 2s linear infinite;

            @keyframes spin {
                100% {
                    -webkit-transform: rotate(360deg);
                    transform:rotate(360deg);
                }
            }
        }

        h1 {
            color: $white;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            margin: 0;
        }
    }
}

.assets-liabilities {
    .MuiToggleButtonGroup-root {
        position: absolute;
        top: 55px;
        left: 480px;
        width: 240px;
        height: 36px;
        background: transparent;

        .MuiButtonBase-root {
            background-color: $white;
            color: #B3B3B3;
            width: 120px;
            height: 36px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        }

        .MuiToggleButtonGroup-grouped:not(:last-child) {
            border-radius: 20px 0px 0px 20px !important;
        }

        .MuiToggleButtonGroup-grouped:not(:first-child) {
            border-radius: 0 20px 20px 0 !important;
        }

        .MuiToggleButton-root.Mui-selected {
            background-color: $brand-primary;
            color: $white;
        }
    }

    .actions {
        width: 100%;
        height: 75px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 35px 0 50px 0;

        .sync-accounts {
            flex: 1.2 !important;
        }

        .sync-accounts, .save-accounts {
            flex: 1.5;
            position: relative;
            top: 15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            &.lower {
                display: none;
            }

            .icon-button {
                position: absolute;
                top: 11px;
                right: -8px;
            }

            p.subtext {
                font-size: 12px;
                line-height: 12px;
                text-align: center;
                color: $brand-primary;
                display: flex;
                align-items: center;

                a {
                    color: $brand-secondary;
                }

                img {
                    width: 12px;
                    padding-left: 5px;
                }
            }
        }

        .net-worth {
            flex: 3;
            display: flex;
            justify-content: flex-end;

            .nw-wrapper {
                background: #F4F6F8;
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
                border-radius: 10px;
                padding: 12px 15px 12px 20px;
                font-size: 21px;
                line-height: 25px;
                color: $brand-primary;

                span.positive {
                    color: $brand-secondary;
                }
            }
        }

        .edit-historical-record.lower {
            display: none;
        }
    }

    .account-table {
        width: 100%;
        min-height: 100px;
        background-color: $white;
        border-radius: 10px;
        padding: 20px 0 35px 0;
        margin: 0 0 60px 0;

        img {
            padding: 0 10px 0 30px;
            display: inline;
        }

        h1 {
            display: inline-block;
        }

        h2 {
            font-weight: normal;
            margin: 5px 0 0 30px;
        }

        .header {
            width: 100%;
            margin: 25px 0 0 0;
            padding: 0 0 10px 0;
            border-bottom: 1px solid #B3B3B3;
            display: flex;
            justify-content: flex-start;

            .header-col {
                width: 20%;
                display: flex;
                flex-wrap: nowrap;
                align-items: flex-end;
                color: #B3B3B3;
                font-size: 16px;
                line-height: 19px;
                font-weight: 700;

                &:nth-child(1) {
                    padding: 0 21px 0 25px;
                    width: 18%;
                }

                &:nth-child(3) {
                    padding: 0 21px 0 0;
                    width: 18%;
                }

                &:nth-child(4) {
                    width: 9%;
                    padding-right: 50px;

                    &.selected {
                        svg {
                            right: 15px;
                        }
                    }
                }

                &:nth-child(5) {
                    width: 9%;
                    padding-right: 50px;

                    &.selected {
                        svg {
                            right: 5px;
                        }
                    }
                }

                &.selected {
                    color: $brand-primary;

                    svg {
                        fill: $brand-primary;
                    }
                }

                label {
                    cursor: pointer;
                }

                svg {
                    cursor: pointer;
                    fill: #B3B3B3;
                    position: relative;
                    top: 3px;
                }
            }
        }

        .body {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .account-row-wrapper {
                width: 100%;

                .account-row {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    position: relative;

                    &:nth-child(even) {
                        background-color: #F4F6F8;
                    }

                    .body-col {
                        width: 20%;
                        color: $brand-primary;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22px;
                        padding: 22px 0 21px 0;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;

                        &:nth-child(1) {
                            padding: 22px 21px 21px 25px;
                            width: 18%;
                        }

                        &:nth-child(3) {
                            padding: 22px 21px 21px 0;
                            width: 18%;
                        }

                        &:nth-child(4) {
                            width: 10%;
                            padding-right: 40px;
                        }

                        &:nth-child(5) {
                            width: 23%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            span {
                                width: 25%;
                            }

                            .icons {
                                width: 25%;
                                display: flex;
                                justify-content: space-between;

                                .icon-button {
                                    cursor: pointer;
                                }
                            }

                            a {
                                flex-grow: 0.2;
                                color: $brand-secondary;
                            }
                        }

                        &.num {
                            font-weight: 700;

                            input {
                                width: 125px;
                            }
                        }
                    }

                    .expand-mobile {
                        display: none;
                    }
                }
            }
        }
    }

    .add-account {
        margin: 20px 0 0 25px;
        display: inline;
        cursor: pointer;

        label {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: $brand-secondary;
            cursor: pointer;
        }

        img {
            padding: 0;
            position: relative;
            left: 15px;
            top: 7px;
            cursor: pointer;
        }
    }

    .total {
        margin: 100px 25px 0 0;
        text-align: right;
        font-weight: 400;
        font-size: 21px;
        line-height: 25px;

        label {
            color: $brand-primary;
        }

        span {
            color: $white;
            font-weight: 700;
            padding: 8px 18px;
            box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
        }

        &.assets {
            span {
                background-color: #7CD4B7;
            }
        }

        &.liabilities {
            span {
                background-color: $status-orange;
            }
        }
    }

    .chart-wrapper {
        background-color: $white;
        border-radius: 10px;
        padding: 22px 45px 31px 15px;
        display: flex;
        flex-direction: row;

        .chart-wrapper-2 {
            display: flex;
            flex-direction: column;

            .chart-options {
                height: 35px;
                display: flex;
                align-items: center;
                margin: 40px 0 50px 0;

                .mobile-band {
                    display: none;
                }

                .option {
                    display: flex;
                    align-items: center;

                    &:nth-child(4) {
                        margin: 0 40px 0 25px;
                    }

                    label {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        color: #B3B3B3;
                        min-width: 70px;
                        padding: 0 10px 0 0;
                        text-align: right;
                    }

                    .datepicker, select {
                        width: 95px;
                        padding: 8px 10px;
                        border: 1px solid rgba(38, 39, 63, 0.5);
                        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
                        border-radius: 5px;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        color: $brand-primary;
                    }

                    button.view {
                        width: 75px;
                        padding: 7px 0 !important;
                    }
                }

                .select-accounts {
                    display: none;
                }
            }

            .config-error {
                text-align: center;
                padding: 15px 0;
                margin-top: -45px;
                color: $status-red;
            }

            .chart-container {
                width: 710px;
                border: 1.5px solid #CFCFCF;
                border-radius: 10px;

                .historical-chart {
                    height: 535px;
                    margin-top: 0px;
                }

                .historical-legend {
                    margin: 0 -1px;
                    top: 0;
                    padding: 0;
                }

                .no-data {
                    padding: 25px;
                }
            }
        }
    }
}

.legend {
    width: 260px;
    height: 100%;
    margin: 0 40px 0 0;

    .legend-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 10px 15px 15px;

        h1 {
            margin: 0;
        }

        a.select-clear-all {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: $brand-secondary;
        }
    }

    .legend-category {
        background: #F4F6F8;
        border-radius: 10px;
        padding: 15px 0;

        &.assets {
            margin: 0 0 25px 0;
        }

        h2 {
            color: #B3B3B3;
            margin: 0;
        }

        .legend-label {
            padding: 10px 25px;
            border-bottom: 2px solid #FFFFFF;

            &:last-child {
                border-bottom: none;
            }

            ul {
                list-style-type: none;
                padding: 0;
                margin: 10px 0 0 0;

                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    .MuiButtonBase-root {
                        padding: 0 4px 0 0;
                        color: $brand-secondary;
                    }

                    label {
                        font-weight: 700;
                        color: $brand-primary;
                        position: relative;
                        top: -1px;
                    }
                }
            }
        }
    }
}

.react-datepicker-popper {
    z-index: 10000;

    .react-datepicker__month-container {
        float: none;
    }

    .react-datepicker {
        background-color: #F4F6F8;
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        border: none;
        font-family: 'Sen';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: rgba(38, 39, 63, 0.5);

        .react-datepicker__triangle {
            display: none;
        }

        .react-datepicker__navigation {
            box-shadow: none;
            top: 0;
        }

        .react-datepicker__navigation--previous {
            left: 20px;
        }

        .react-datepicker__navigation--next {
            right: 20px;
        }

        .react-datepicker__navigation-icon::before {
            border-color: $brand-primary;
            border-width: 2px 2px 0 0;
        }

        .react-datepicker__header {
            background-color: #F4F6F8;
            border-bottom: 2px solid $white;
        }

        .react-datepicker__day-name, .react-datepicker__day {
            color: rgba(38, 39, 63, 0.5);
        }

        .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
            background-color: $brand-secondary;
        }

        .react-datepicker__day--outside-month, .react-datepicker__day--disabled {
            color: rgba(38, 39, 63, 0.2) !important;
        }
    }
}

.account-types {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;

    .type {
        width: 43%;
        background-color: $white;
        border-radius: 10px;
        padding: 23px 17px;
        text-align: left;

        button {
            display: block;
        }

        .add-account-security {
            display: flex;
            align-items: flex-start;
            margin-top: 30px;

            p {
                margin: -4px 0 0 10px;
                font-size: 12px;
                line-height: 16px;

                a {
                    color: $brand-secondary;
                }
            }
        }
    }
}

.account-dialog-content {
    .adc-wrapper {
        width: 95%;
        margin: 20px 0 0 0;
        background-color: $white;
        border-radius: 10px;
        padding: 18px;
        display: flex;
        flex-wrap: wrap;

        .back-link {
            text-align: left;
            margin: 0 90% 20px 0;
        }

        form#metadata {
            flex-basis: 48%;
            margin: 0 0 0 20px;
        }

        form#reminderFrequency {
            flex-basis: 48%;
        }

        fieldset {
            border: none;
            text-align: left;

            &.custom-range {
                display: flex;
                height: 35px;
                align-items: center;

                h2 {
                    margin: 0 0 0 5px;
                    width: 55px;
                }
            }

            h2 {
                position: relative;
                margin: 0 0 8px 5px;

                .icon-button {
                    top: -1px;
                    right: -5px;
                }
            }

            input[type=text], select {
                width: 200px;
                border: 1px solid #26273F;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
                border-radius: 5px;
                padding: 8px 10px;
                margin: 0 0 28px 0;
                display: block;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $brand-primary;

                &#accountType {
                    width: 222px;
                    appearance: none;
                }

                &#customNum {
                    width: 60px;
                    margin: 0 15px 0 0;
                }

                &#customFreq {
                    width: 105px;
                    margin: 0;
                }

                &::placeholder {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #B3B3B3;
                }
            }

            .radio-group {
                position: relative;
                left: 55px;

                label {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #262832;
                    position: relative;
                    top: -1px;
                    display: inline-block;
                    width: 255px;
                    margin: 0 0 15px 3px;
                }
            }

            ul.weekdays {
                list-style-type: none;
                display: flex;
                margin: 0;
                padding: 0;

                li {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    flex: 0 0 15%;

                    .MuiCheckbox-colorSecondary.Mui-checked {
                        color: $brand-secondary;
                    }
                }
            }

        }

        a.update-reminders {
            display: none;
        }

        button.primary {
            margin: 0 0 0 auto !important;
        }
    }

    .delete-account {
        text-align: left;
        padding: 15px 0 0 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        h2 {
            color: $brand-primary;
            flex-basis: 100%;
        }

        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: $brand-primary;
            flex-basis: 100%;
            margin: 0 !important;
        }

        .action {
            display: flex;
            justify-content: space-between;
            align-items: center;

            input[type=text] {
                width: 100px;
                border: 1px solid $brand-primary;
                filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
                border-radius: 5px;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $brand-primary;
                padding: 8px 10px;
                margin: 30px 20px 0 0;
                background: #F4F6F8;

                &::placeholder {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #B3B3B3;
                }
            }

            button.delete {
                background: #F4F6F8;
                border: 2px solid #B3B3B3;
                box-shadow: 2px 2px 2px rgb(0 0 0 / 15%);
                border-radius: 20px !important;
                color: #B3B3B3;
                padding: 6px 23px;
                font-family: "Jost" !important;
                font-style: normal;
                font-weight: 500;
                font-size: 16px !important;
                line-height: 23px !important;
                margin: 30px 0 0 0;
                cursor: auto;

                &.active {
                    background: #EB535C;
                    border: 2px solid #EB535C;
                    color: $white;
                    cursor: pointer;
                }
            }
        }
    }
}

.edit-historical-actions {
    display: flex;
    width: 28%;
    justify-content: space-between;
    margin: 35px auto;
}

.remember {
    display: flex;
    align-items: center;
    margin: 0 auto;

    .MuiCheckbox-colorSecondary.Mui-checked {
        color: $brand-secondary;
    }

    label {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #8D8E9B;
    }
}

.pending {
    color: $status-orange;
}

.overdue {
    color: $status-red;
}

@media (max-width: 1439px) {
    .assets-liabilities {
        .MuiToggleButtonGroup-root {
            position: static;
            display: block;
            width: 305px;
            height: 50px;
            margin: 10px auto 45px auto;

            .MuiButtonBase-root {
                width: 153px;
                height: 50px;
            }

            .MuiToggleButtonGroup-grouped:not(:last-child) {
                border-radius: 10px 0px 0px 10px !important;
            }

            .MuiToggleButtonGroup-grouped:not(:first-child) {
                border-radius: 0 10px 10px 0 !important;
            }
        }

        h1 {
            margin: 0 0 5px 20px;
        }

        h2 {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            margin: 0;
        }

        .actions {
            width: auto;
            height: auto;
            flex-direction: column;
            justify-content: center;
            margin: 35px 0 15px 0;

            .sync-accounts {
                margin-bottom: 35px;

                button {
                    height: 40px;
                }
            }

            .save-accounts {
                display: none;

                &.lower {
                    display: block;
                    margin: 0 auto 30px auto;

                    button {
                        height: 40px;
                    }

                    .icon-button {
                        right: -25px;
                    }
                }
            }

            .edit-historical-record {
                display: none;

                &.lower {
                    display: block;
                    margin: 0 auto 30px auto;

                    button {
                        height: 40px;
                    }
                }
            }

            .net-worth {
                .nw-wrapper {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 20px;
                    height: 20px;
                    padding: 15px 20px;
                }
            }
        }

        .account-table {
            margin: 0 0 50px 0;
            border-radius: 0;

            h1 {
                margin: 0;
                position: relative;
                top: -5px;
            }

            .header {
                border-bottom: none;

                .header-col {
                    &:nth-child(1) {
                        width: 49%;
                    }

                    &:nth-child(2) {
                        width: 43%;
                    }

                    &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                        display: none;
                    }

                    label {
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
            }

            .body {
                .account-row-wrapper {
                    width: 95%;

                    .account-row {
                        width: calc(100% - 15px);
                        height: 23px;
                        margin: 15px 10px 0 10px;
                        padding: 16px 0 15px 15px;
                        box-shadow: 0px 0px 4px rgba(38, 40, 50, 0.2);
                        border-radius: 10px;
                        background-color: $white;

                        &:hover {
                            cursor: pointer;
                        }

                        &:nth-child(even) {
                            background-color: $white;
                        }

                        .body-col {
                            padding: 0 !important;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;

                            &:nth-child(1) {
                                width: 57%;
                            }

                            &:nth-child(2) {
                                width: 30%;
                            }

                            &:nth-child(3), &:nth-child(4) {
                                display: none;
                            }

                            &:nth-child(5) {
                                width: 13%;
                                position: absolute;
                                right: -5px;

                                a, span {
                                    display: none;
                                }

                                .icons {
                                    img {
                                        width: 15px;
                                        height: 15px;
                                        padding: 0;

                                        &:nth-child(2) {
                                            position: absolute;
                                            left: 25px;
                                        }
                                    }
                                }
                            }

                            &.num {
                                input {
                                    width: 85px;
                                    border: 1px solid #26273F;
                                    border-radius: 5px;
                                    font-size: 16px;

                                    &:focus-visible {
                                        outline: none;
                                    }
                                }
                            }
                        }
                    }

                    .expand-mobile {
                        display: block;
                        width: calc(100% - 30px);
                        height: 155px;
                        background-color: #F4F6F8;
                        margin: -10px 0 0 10px;
                        padding: 35px 15px;

                        .expand-row {
                            display: flex;
                            margin-bottom: 30px;

                            label {
                                width: 62%;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                            }

                            span {
                                width: 43%;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                color: $brand-primary;
                            }
                        }

                        a {
                            color: $brand-secondary;
                            text-decoration: none;
                            font-weight: 700;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        .add-account {
            margin: 20px 0 0 24px;
            display: flex;
            align-items: center;

            label {
                font-size: 16px;
                line-height: 19px;
            }

            img {
                width: 18px;
                height: 18px;
                top: 0;
                left: 5px;
            }
        }

        .total {
            margin: 40px 10px 0 0;
            font-size: 18px;
            line-height: 22px;

            span {
                background-color: transparent !important;
                box-shadow: none;
                color: $brand-primary;
                padding: 0 18px 0 0;
            }
        }

        .chart-wrapper {
            width: 100%;
            height: 995px;
            padding: 0;
            border: none;
            border-radius: 0;

            .legend {
                display: none;
            }

            .chart-wrapper-2 {
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                border-radius: 0;
                position: relative;

                .chart-options {
                    width: 100%;
                    height: 420px;
                    flex-direction: column;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    margin: 0;

                    .mobile-band {
                        width: 100%;
                        height: 45px;
                        background-color: #E6ECEE;
                        display: block;
                    }

                    .option {
                        width: 70%;
                        height: 35px;
                        padding: 35px 0 0 0;
                        display: flex;
                        justify-content: center;

                        &:nth-child(4) {
                            margin: 0;
                        }

                        label {
                            width: 70px;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 17px;
                            color: $brand-primary;
                        }

                        .react-datepicker-wrapper {
                            width: 144px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                        }

                        select {
                            width: 144px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            border: 1px solid $brand-primary;
                        }

                        .datepicker {
                            width: 122px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            border: 1px solid $brand-primary;
                        }

                        button.view {
                            margin: 0 0 0 auto;
                        }
                    }

                    .select-accounts {
                        display: block;
                        width: 70%;
                        text-align: right;
                        margin: 0 auto;
                        padding: 35px 0 0 0;
                        color: $brand-secondary;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .config-error {
                    position: absolute;
                    bottom: 366px;
                    font-size: 14px;
                    width: 95%;
                    left: 3%;
                }

                .chart-container {
                    width: 100%;
                    border: none;

                    .historical-chart {
                        height: 375px;
                    }

                    .historical-legend {
                        height: 275px;
                    }
                }
            }
        }
    }

    .account-dialog-content {
        .adc-wrapper {
            a.update-reminders {
                display: block;
                margin: 25px 0 0 0;
                color: $brand-secondary;

                &.hide {
                    display: none;
                }
            }

            #metadata.closed {
                display: none;
            }

            #reminderFrequency {
                display: none;

                &.open {
                    display: block;

                    h2 {
                        padding: 0;
                    }

                    fieldset {
                        padding: 15px 0 0 15px;

                        &.custom-range {
                            padding: 10px 0 0 15px;

                            select#customNum {
                                width: 60px;
                                margin: 0 15px 0 0;
                            }

                            select#customFreq {
                                width: 105px;
                            }
                        }

                        .radio-group {
                            left: 60px;
                            width: 180px;

                            input[type=radio] {
                                width: auto;
                            }

                            label {
                                width: 90%;
                            }
                        }

                        ul {
                            flex-wrap: wrap;
                            margin: 0 0 20px 0;

                            li {
                                flex: 0 0 25%;
                                flex-direction: row;
                                align-items: center;
                            }
                        }

                        select#monthDay {
                            margin: 0 0 25px 0;
                        }
                    }
                }
            }
        }
    }

    .mobile-legend {
        width: auto;
        height: auto;
        margin: 0;
        padding: 50px 10px;

        h1, h2 {
            margin: 0 !important;
            text-align: left;
            padding: 0 !important;
        }
    }

    .edit-historical-actions {
        width: 100%;
        margin: 0 0 30px 0;
    }
}
