@import '../../styles/vars';

.logged-out {
    h1, p {
        margin: 0 0 25px 0;
    }

    a {
        display: inline-block;
        color: $brand-secondary;
        margin: 0;
    }

}

@media (max-width: 1439px) {
    .logged-out {
        min-height: 250px;

        h1, p {
            margin: 25px 20px;
        }

        a {
            margin: 0 0 25px 20px;
        }
    }
}
