@import '../../styles/vars';

.cashflow-table {
    .header {
        width: calc(100% - 42px);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 0 0 42px;

        .column {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: $brand-primary !important;
            padding: 0 0 10px 0;

            span.icon-button {
                position: relative;
                left: 5px;
                top: -2px;
            }

            span.sub {
                font-weight: 400;
                font-size: 12px;
                width: 100%;
                display: inline-block;
            }
        }
    }

    .body {
        .row {
            width: 100%;
            height: 35px;
            margin: 0 0 30px 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .action {
                width: 25px;
                height: 25px;
                margin-right: 15px;
                cursor: pointer;
            }

            .field {
                input, select {
                    background: #FFFFFF;
                    border: 1px solid #26273F;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
                    border-radius: 5px;
                    padding: 8px 0 8px 10px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: $brand-primary !important;

                    &::placeholder {
                        color: #B3B3B3;
                    }
                }

                label, span.amount {
                    display: inline-block;
                    background-color: #F4F6F8;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
                    border-radius: 5px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: $brand-primary !important;
                    padding: 8px 10px;
                }
            }

            .low-high {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                span {
                    display: inline-block;
                    width: 60px;
                    margin: 0 5px 0 0;
                    font-size: 14px;

                    &:nth-of-type(2) {
                        width: 25px;
                        margin: 0 5px;
                    }
                }
            }

            .notes {
                .comment-icon {
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    background-repeat: no-repeat;
                }
            }

            .savings-withdrawal {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                width: 275px;
                height: 70px;

                .MuiButtonBase-root {
                    padding: 0 10px 0 0;
                    color: $brand-secondary;
                }

                span {
                    font-size: 12px;
                }
            }
        }
    }

    .source, .account {
        width: 205px;
        margin-right: 25px;
    }

    .frequency {
        width: 150px;
        margin-right: 25px;
    }

    .amount {
        width: 282px;
        margin-right: 25px;

        &.lh {
            width: 295px;
            margin-right: 10px;

            input {
                width: 85px;
                margin: 0;
            }
        }
    }

    .notes {
        width: 50px;
        margin-right: 25px;
    }

    .average {
        width: 150px;
        margin-right: 25px;
    }

    .category {
        width: 275px;
        margin-right: 25px;
    }

    .low, .high {
        width: 100px;
        margin-right: 25px;
    }

    .description {
        width: 250px;
    }

    .difference, .actual {
        width: 150px;
        margin-right: 25px;
    }

    .date {
        width: 105px;
        margin-right: 35px;
    }

    .name {
        width: 205px;
        margin-right: 35px;
    }

    .label {
        width: 250px;
    }

    &.even {
        .body {
            .row {
                .field {
                    label, span.amount {
                        background-color: $white !important;
                    }
                }
            }
        }
    }

    .add-row {
        max-width: 250px;
        display: flex;
        align-items: center;
        cursor: pointer;

        label {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: $brand-secondary;
            cursor: pointer;
        }

        img {
            width: 25px;
            height: 25px;
            margin-left: 10px;
            cursor: pointer;
        }
    }

    .spending {
        color: $status-red !important;
    }
}

div[role=tooltip] {
    z-index: 1000;

    .note {
        width: 400px;
        height: 200px;
        background: #FEFFC1;
        border: 1px solid #B3B3B3;
        border-radius: 5px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;

        textarea {
            width: calc(100% - 30px);
            height: 62%;
            border: none;
            background: transparent;
            outline: none;
            resize: none;
            box-shadow: none;
            overflow: hidden;
            font-size: 18px;
            color: $brand-primary;
            padding: 10px 15px;
        }

        button {
            width: 90px;
            height: 30px;
            color: $white;
            background: $brand-secondary;
            border: none;
            padding: 0 !important;
            margin: 12px;
        }
    }
}

@media (max-width: 1439px) {
    .cashflow-table {
        .row {
            position: relative;
            margin: 0 0 20px 20px;
            clear: both;

            &.rp {
                margin: 0 0 20px 30px;
            }

            &.collapsed {
                height: 33px;
                background-color: #F4F6F8;
                padding: 15px 40px;
                margin: 0 -20px;

                .row-content {
                    background: #FFFFFF;
                    box-shadow: 0px 0px 4px rgba(38, 40, 50, 0.2);
                    border-radius: 5px;
                    padding: 8px 10px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    line-height: 17px;

                    label {
                        font-weight: 400;
                        padding: 0;
                        max-width: 60%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    span {
                        font-weight: 700;
                        margin: 0 0 0 auto;
                    }

                    img {
                        width: 13px;
                        height: 13px;
                        padding: 0 0 0 10px;
                    }
                }
            }

            .action.rp {
                position: absolute;
                top: 4px;
                left: -32px;
            }

            .field {
                height: 60px;
                width: 100%;
                margin: 0 !important;

                &.category > div > div {
                    width: 240px !important;
                }

                &.actual {
                    label {
                        color: #E5626A;
                    }
                }

                label {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    display: block;
                    margin: 20px 0 10px 0;
                }

                input, select {
                    width: 84%;
                    background: #FFFFFF;
                    border: 1px solid #26273F;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
                    border-radius: 5px;
                    padding: 8px 10px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                }

                select {
                    width: 92%;
                }

                .low-high {
                    display: flex;
                    justify-content: space-between;
                    width: 92%;

                    input {
                        width: 36%;
                        margin: 0 !important;
                    }
                }

                &.average {
                    float: left;
                    width: 49%;
                    height: 100px;

                    label.average {
                        margin-top: 0;
                    }
                }

                &.notes {
                    float: right;
                    width: 49%;

                    .notes {
                        width: 35px;
                        margin: 30px 25px;

                        .comment-icon {
                            width: 30px;
                            height: 30px;
                            background-repeat: no-repeat;
                        }
                    }
                }

                &.amount {
                    .low-high {
                        align-items: center;

                        span {
                            font-size: 14px;
                        }

                        :nth-child(3) {
                            padding: 0 7px;
                        }

                        input {
                            color: $brand-primary !important;
                        }
                    }
                }

                &.savings_withdrawal {
                    height: 150px;

                    .savings-withdrawal {

                    }
                }
            }

            .update-label {
                width: 84%;
                background-color: #F4F6F8;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                padding: 8px 10px;
                margin: 0 0 20px 0;
                font-size: 14px;
                line-height: 17px;

                &:first-child {
                    margin-top: 20px;
                }
            }
        }

        .add-row {
            clear: both;
            padding: 10px 0 0 20px;

            &.expanded {
                width: 185px;
                margin-top: 15px;

                label {
                    margin-right: 15px;
                }
            }

            img {
                margin: 0;
            }
        }
    }

    div[role=tooltip] {
        .note {
            width: 95%;
            height: 200px;

            textarea {
                width: calc(100% - 30px);
                height: 62%;
                border: none;
                background: transparent;
                outline: none;
                resize: none;
                box-shadow: none;
                overflow: hidden;
                font-size: 18px;
                color: $brand-primary;
                padding: 10px 15px;
            }

            button {
                width: 90px;
                height: 30px;
                color: $white;
                background: $brand-secondary;
                border: none;
                padding: 0 !important;
                margin: 12px;
            }
        }
    }
}
