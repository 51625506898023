@import '../../styles/vars';

.forgot-password {
    h1 {
        margin: 0 0 40px 0;
    }

    .form-wrapper {
        height: auto;

        form {
            height: auto;
        }
    }
}

@media (max-width: 1439px) {
    .forgot-password {
        h1 {
            margin: 25px 20px;
        }
    }
}
