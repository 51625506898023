@import '../../styles/vars';

.edit-historical {
    .eh-content {
        background: $white;
        border-radius: 10px;
        padding: 25px 42px;
        margin: 35px 0 0 0;

        .label-datepicker {
            display: flex;
            align-items: center;

            label {
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: $brand-primary;
                width: 120px;
            }

            .datepicker {
                width: 95px;
                padding: 8px 10px;
                border: 1px solid rgba(38, 39, 63, 0.5);
                filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
                border-radius: 5px;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: $brand-primary;

                &::placeholder {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #B3B3B3;
                }
            }
        }

        .edit-accounts {
            display: flex;
            justify-content: space-around;

            .edit-section {
                padding: 25px 42px;
                background: #F4F6F8;
                border-radius: 10px;
                margin: 30px 0 40px 0;
                display: flex;
                flex-direction: column;
                width: 360px;

                h2 {
                    margin: 0;
                }

                .account-row {
                    margin: 25px 0 0 0;

                    &:nth-last-child(2) {
                        margin: 25px 0 75px 0;
                    }

                    label {
                        width: 100%;
                        display: block;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: $brand-primary;
                        margin: 0 0 10px 0;
                    }

                    input {
                        width: 93%;
                        display: block;
                        background: #FFFFFF;
                        border: 1px solid #26273F;
                        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
                        border-radius: 5px;
                        padding: 9px 10px;
                        font-size: 16px;
                        line-height: 19px;
                        color: $brand-primary;
                    }
                }

                .total {
                    margin: auto -15px 0 0;
                }
            }
        }

        .save-accounts {
            margin: 0 25px 0 auto;
            text-align: right;

            button.primary {
                margin-right: 15px;
            }
        }
    }
}

@media (max-width: 1439px) {
    .edit-historical {
        h1 {
            margin: 25px 20px 8px 20px;
        }

        .eh-content {
            border-radius: 0px;
            padding: 0;

            .label-datepicker {
                width: 90%;
                padding: 25px 0 25px 42px;

                label {
                    width: 85%;
                    font-size: 14px;
                    line-height: 17px;
                }
            }

            .edit-accounts {
                flex-direction: column;

                .edit-section {
                    border-radius: 0;
                    margin: 0 0 25px 0;

                    h2 {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                    }

                    .account-row {
                        input {
                            width: 63%;
                        }

                        &:nth-last-child(2) {
                            margin: 25px 0 30px 0;
                        }
                    }

                    .total {
                        text-align: left;
                        margin: 0;

                        label {
                            font-size: 12px;
                            line-height: 14px;
                            display: block;
                            margin: 0 0 10px 0;
                        }

                        span {
                            display: inline-block;
                            color: $white;
                            padding: 8px 18px;
                            box-shadow: 2px 2px 2px rgb(0 0 0 / 5%);
                        }

                        &.assets {
                            span { background-color: #7CD4B7 !important; }
                        }

                        &.liabilities {
                            span { background-color: #FFA34F !important; }
                        }
                    }
                }
            }
        }

        .save-accounts {
            margin: 0 auto !important;
            padding: 20px 0 40px 0;
            display: flex;
            justify-content: center;
        }
    }
}
