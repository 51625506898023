@import 'vars';

html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Sen';
    font-style: normal;

    body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        overflow: auto !important;

        h1 {
            font-size: 21px;
            line-height: 25px;
            margin: 0 0 5px 0;
        }

        h2 {
            font-size: 16px;
            line-height: 19px;

            &.sub {
                margin-left: 40px;
                display: inline;

                &.light {
                    font-weight: 400;
                }

                &.heavy {
                    font-weight: 700;
                }
            }

            span {
                font-weight: 300;
            }
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        button, input[type=submit] {
            box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
            border-radius: 20px !important;
            padding: 7px 28px !important;
            font-weight: 500;
            font-size: 16px !important;
            line-height: 23px !important;
            font-family: 'Jost' !important;
            text-transform: unset !important;
            cursor: pointer;
            width: fit-content;

            &.primary {
                border: 1px solid $brand-secondary !important;
                background-color: $brand-secondary !important;
                color: $white;
            }

            &.secondary {
                border: 1.5px solid $brand-secondary !important;
                background-color: transparent !important;
                color: $brand-secondary !important;
            }

            &.larger {
                font-size: 21px !important;
                line-height: 30px !important;
                padding: 5px 25px !important;
            }
        }

        .wrapper {
            width: 100%;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;

            .nav {
                width: 100%;
                background-color: $brand-primary;
            }

            .main-bg {
                width: 100%;
                height: 100px;
                background-color: $brand-primary;
                position: absolute;
                top: 70px;
                z-index: 1;
            }

            .main {
                width: 100%;
                background-color: $brand-tertiary;
                border-radius: 0;
                z-index: 10;
                position: relative;
                top: 70px;
            }

            .footer {
                width: 100%;
                z-index: 500;
            }
        }

        .historical-chart {
            margin-top: 30px;
            height: 400px;

            .recharts-wrapper {
                background-color: $white;
                border-radius: 0;

                .recharts-legend-wrapper {
                    background-color: $brand-primary;
                    border-radius: 0;
                    left: 0px !important;
                    bottom: 0px !important;

                    .recharts-default-legend {
                        padding: 10px !important;

                        .recharts-legend-item {
                            width: 125px;
                            padding: 0 15px 20px 0;

                            svg {
                                margin-right: 8px !important;
                            }

                            span {
                                position: relative;
                                top: 2px;
                                font-size: 16px;
                                line-height: 19px;
                            }
                        }
                    }
                }
            }
        }

        .historical-legend {
            height: 295px;
            background-color: $brand-primary;
            color: $white;

            ul {
                padding: 25px 35px 25px 60px;
                margin: 0;
                list-style-type: none;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;

                li {
                    width: 90%;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    padding: 0 0 15px 0;

                    .icon {
                        width: 10px;
                        height: 10px;
                        margin: 0 6px 0 0;

                        &.circle {
                            border-radius: 50%;
                        }
                    }

                    span {
                        color: $white;
                        font-weight: 700;
                    }
                }
            }
        }

        .recharts-tooltip-cursor {
            stroke-linecap: square;
        }

        .progress {
            width: 78%;

            .progress-bar {
                height: 15px;
                border-width: 0;
                border-radius: 30px;
                background-color: $brand-tertiary;
                margin: 0 0 15px 0;

                .pace {
                    height: 15px;
                    border-width: 0;
                    border-radius: 30px;
                    background-color: $brand-secondary;
                }
            }

            .progress-details {
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: $brand-primary;

                span {
                    font-weight: 800;
                    color: $brand-secondary;
                }
            }
        }

        .form-wrapper {
            width: 100%;
            height: 500px;
            background-color: $white;
            border-radius: 0;
            padding: 25px 0;
            margin: 0;

            form {
                width: calc(100% - 20px);
                height: 350px;
                margin: 0;
                display: block;
                padding: 10px;
                background-color: #F4F6F8;
                border-radius: 0;

                h2 {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    color: $brand-primary;
                    margin: 0 0 20px 0;
                }

                fieldset {
                    border: none;
                    padding: 0;
                    margin: 0 0 30px 0;
                    height: 65px;

                    label {
                        display: block;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        margin: 0 0 10px 0;
                    }

                    input {
                        width: 90%;
                        padding: 8px 10px;
                        background-color: $white;
                        border: 1px solid #26273F;
                        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
                        border-radius: 5px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: $brand-primary;

                        ::placeholder {
                            color: #B3B3B3;
                        }
                    }

                    .ReactPasswordStrength {
                        border: none;
                        width: 99%;

                        input[type=password] {
                            width: 100%;
                        }

                        .ReactPasswordStrength-strength-bar {
                            height: 5px;
                        }

                        .ReactPasswordStrength-strength-desc {
                            position: relative;
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .badge {
            width: 25px;
            height: 25px;
            color: $white;
            background-color: $status-red;
            font-weight: 700;
            font-size: 17px;
            line-height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
        }
    }
}

@media (min-width: 1440px) {
    html {

        body {

            h1, h2, h3, h4, h5, h6 {
                font-weight: 700;
                color: $brand-primary;
                margin: 0;
                padding: 0;
                margin: 0 0 10px 0;
            }

            h1 {
                font-size: 24px;
                line-height: 29px;
            }

            h2 {
                &.sub {
                    margin-left: 30px;
                }
            }

            h3 {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
            }

            p {
                font-size: 18px;
                line-height: 24px;
                font-weight: 400;
            }

            .wrapper {
                flex-direction: row;
                flex-wrap: wrap;
                position: relative;
                background-color: $brand-tertiary;

                .nav {
                    width: 240px;

                    section {
                        width: 240px;
                        scrollbar-width: none;
                        overflow-y: scroll;
                        position: fixed;
                        top: 0;
                        bottom: 0;
                        z-index: 1000;
                        background-color: $brand-primary;
                    }
                }

                .main-bg {
                    width: 100px;
                    top: 0px;
                    left: 240px;
                }

                .main {
                    width: 1073px;
                    min-height: 100vh;
                    border-radius: 40px 0px 0px 0px;
                    padding: 55px;
                    top: 0px;
                }

                .footer {
                    width: 100%;
                    height: 525px;
                }
            }

            .historical-chart {
                height: 280px;

                .recharts-wrapper {
                    border-radius: 10px;
                    z-index: 100;

                    .recharts-legend-wrapper {
                        border-radius: 0 0 10px 10px;

                        .recharts-default-legend {
                            padding: 15px 30px !important;
                        }
                    }
                }
            }

            .historical-legend {
                height: 125px;
                position: relative;
                top: -10px;
                z-index: 10;
                padding: 10px 0 0 0;
                border-radius: 0px 0px 10px 10px;

                &.dashboard {
                    height: 160px;

                    li {
                        width: 50%;
                    }
                }

                ul {
                    padding: 15px 25px;
                    flex-direction: row;

                    li {
                        width: 33%;
                        height: 20px;
                        padding: 0 0 15px 0;

                        .icon {
                            width: 15px;
                            height: 15px;
                            margin: 0 8px 0 5px;

                            &.circle {
                                border-radius: 50%;
                            }
                        }

                        span {
                            color: $white;
                        }
                    }
                }
            }

            .progress {
                width: 370px;

                .progress-bar {
                    height: 20px;
                    border-width: 0;
                    border-radius: 30px;
                    background-color: $brand-tertiary;
                    margin: 0 0 15px 0;

                    .pace {
                        height: 20px;
                        border-width: 0;
                        border-radius: 30px;
                        background-color: $brand-secondary;
                    }
                }

                .progress-details {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: $brand-primary;

                    span {
                        font-weight: 800;
                        color: $brand-secondary;
                    }
                }
            }

            .form-wrapper {
                width: 100%;
                padding: 50px 0;
                margin: 0 20px 25px 20px;
                border-radius: 10px;

                form {
                    width: 50%;
                    margin: 0 auto;
                    padding: 25px 35px;
                    border-radius: 10px;

                    fieldset {
                        input {
                            width: 320px;
                        }

                        .ReactPasswordStrength {
                            width: 340px;

                            input[type=password] {
                                width: 340px;
                            }

                            .ReactPasswordStrength-strength-desc {
                                margin: 0;
                                padding: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@font-face {
    font-family: 'Sen';
    src: url('../fonts/Sen-Bold.woff2') format('woff2'),
        url('../fonts/Sen-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sen';
    src: url('../fonts/Sen-Regular.woff2') format('woff2'),
        url('../fonts/Sen-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: "Jost";
  src: url("../fonts/Jost-Italic-VariableFont_wght.ttf") format("ttf");
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: oblique 0deg 20deg;
}
